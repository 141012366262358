<template lang="pug">
  div
    h1 Добро пожаловать

    CCard
      CCardBody Добро пожаловать в систему аналитики Wildberries

    <!--CCard
      CCardHeader Лучшие товары по продажам за последние 7 дней
      CCardBody
        div(v-for="item in bestGoods")
          div
            CCard
              CCardBody(style="display:flex; flex-direction: row")
                div
                  img(:src="item[0].imgLink", style="max-height:120px")
                CCol(md="6", style="")
                  div( @click="$router.push({name: 'Good', params: {id: item[1].gid}})", style="color: blue; font-size: 18px; cursor: pointer;") {{item[0].name}}
                  div Категория:
                    span(@click="$router.push({name: 'Category', params:{id: item[2].Id}})", style="color: gray; cursor: pointer;")  {{item[2].path}}
                  div Продавец:
                    span(@click="$router.push({name: 'Seller', params:{id: item[4]}})", style="color: gray; cursor: pointer;")  {{item[3]}}
                div(style="margin: 0 50px")
                  img(:src="'/img/services/'+item[0].service+'.ico'")
                div(style="border-left: 1px solid gray; padding-left: 35px")
                  div Продаж: {{format(item[1].sales)}}
                  div Оборот: {{format(item[1].oborot)}}
                  div Отзывы: {{format(item[0].reviews)}}-->

</template>

<script>

export default {
  name: 'Dashboard',
  components: {
  },
  data () {
    return {
      bestGoods: []
    }
  },
  methods: {
    format(number) {
      return (new Intl.NumberFormat('ru-RU').format(Math.round(number))).toString();
    }
  },
  mounted() {
    let that =this;
    window.REST.get('goods/bestgoods').then(res=>{
      that.$set(that, 'bestGoods', JSON.parse(res.data.cache));
    })
  }
}
</script>
